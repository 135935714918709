.table :global(.ant-table-header) {
  padding: 0px !important;
  margin-right: -16px !important;
}

.cell {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.groupCell {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  font-weight: bold;
  background-color: #cccccc !important;
}

.productCell {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.periodHeaderCell {
  text-align: right !important;
}

.pastPeriodHeaderCell {
  background-color: rgb(218, 255, 218) !important;
}

.currentPeriodHeaderCell {
  background-color: rgb(190, 231, 255) !important;
}

.futurePeriodHeaderCell {
  background-color: rgb(255, 189, 189) !important;
}

.periodCell {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  text-align: right !important;
}

.pastPeriodCell {
  background-color: rgba(218, 255, 218, 0.205) !important;
}

.currentPeriodCell {
  background-color: rgb(190, 231, 255, 0.205) !important;
}

.futurePeriodCell {
  background-color: rgb(255, 189, 189, 0.205) !important;
}

.clickableCell {
  cursor: pointer;
}

.zeroValue {
  color: #CCC;
}

.trafficLightGreen {
  background-color: rgb(142, 204, 142) !important;
}

.trafficLightOrange {
  background-color: rgb(252, 210, 132) !important;
}

.trafficLightRed {
  background-color: rgb(241, 126, 126) !important;
}

.selected {
  background-color: blue !important;
}
